import { ERROR_MESSAGE, REDEEM_WITH_POINT, SET_REDEEM_COIN } from "./type";

const initState = {
  isRedeemSuccessBoxShow: false,
  errorMessage: "",
  redeemPoint: 0,
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case REDEEM_WITH_POINT:
      return {
        ...state,
        isRedeemSuccessBoxShow: action.payload,
      };
    case ERROR_MESSAGE:
      return{
        ...state,
        errorMessage: action.payload,
      }
    case SET_REDEEM_COIN:
      return {
        ...state,
        redeemPoint: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;

export const setIsRedeemSuccessBoxShow = (payload) => ({
  type: REDEEM_WITH_POINT,
  payload,
});

export const setRedeemPoint = (payload) => ({
  type: SET_REDEEM_COIN,
  payload,
});

export const set_error_message = (payload) => ({
  type: ERROR_MESSAGE,
  payload,
})
