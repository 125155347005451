import * as type from "./type";

export const initState = {
    isLoading: false,
    reasonForDeleteUser: [],
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case type.GET_REASON_FOR_DELETE_USER:
            return {
                ...state,
                reasonForDeleteUser: action.payload,
            }
        case type.START_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case type.STOP_LOADING:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}

export default Reducer;

export const setStartLoading = () => ({
    type: type.START_LOADING
})

export const setStopLoading = () => ({
    type: type.STOP_LOADING
})

export const setReasonForDeleteUser = (payload) => ({
    type: type.GET_REASON_FOR_DELETE_USER,
    payload,
})