import * as type from "./type";

export const initialState = {
  isLoading: false,
  errorMessage: "",
  successMessage: "",
  isShowSuccessBox: false,
  questionLists: {},
  specialDays: [],
};

/**
 * @param action
 */
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.START_SURVEY_PAGE_LOADING:
      return {
        ...getCommonState(state),
        isLoading: true,
      };
    case type.STOP_SURVEY_PAGE_LOADING:
      return {
        ...getCommonState(state),
        isLoading: false,
      };
    case type.ERROR_MESSAGE:
      return {
        ...getCommonState(state),
        errorMessage: action.payload,
      };
    case type.QUESTION_LISTS:
      return {
        ...getCommonState(state),
        questionLists: action.payload,
      };
    case type.SPECIAL_DAYS:
      return {
        ...getCommonState(state),
        specialDays: action.payload,
      };

    case type.SET_SUCCESS_MESSAGE:
      return {
        ...getCommonState(state),
        successMessage: action.payload,
      };

    default:
      return state;
  }
};
export default Reducer;

/**
 *
 * @param {*} state - Initial State Value
 * @returns
 */
const getCommonState = (state) => ({
  ...state,
  isLoading: false,
  dataExist: true,
});

/**
 * Action
 */
export const set_start_survey_loading = () => ({
  type: type.START_SURVEY_PAGE_LOADING,
});

export const set_stop_survey_loading = () => ({
  type: type.STOP_SURVEY_PAGE_LOADING,
});

export const set_error_message = (message = "") => ({
  type: type.ERROR_MESSAGE,
  payload: message,
});

export const set_question_lists = (data = {}) => ({
  type: type.QUESTION_LISTS,
  payload: data,
});

export const set_special_days = (data = {}) => ({
  type: type.SPECIAL_DAYS,
  payload: data,
});

export const set_success_message = (message = "", isFav = {}) => ({
  type: type.SET_SUCCESS_MESSAGE,
  payload: message,
});
