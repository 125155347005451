import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import * as type from "./type";

const initialState = {
  isLoading: false,
  rewardProducts: [],
  totalPages: 0,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.START_GET_ALL_REWARD_PRODUCTS:
      return {
        ...state,
        isLoading: true,
      };

    case type.STOP_GET_ALL_REWARD_PRODUCTS:
      return {
        ...state,
        isLoading: false,
      };

    case type.SET_ALL_REWARD_PRODUCTS:
      return {
        ...state,
        rewardProducts: action.payload,
      };

    case type.SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };

    case type.CLEAR_REWARD_PRODUCTS:
      return {
        ...state,
        rewardProducts: [],
      };

    default:
      return state;
  }
};

export default Reducer;

export const setStartLoading = () => ({
  type: type.START_GET_ALL_REWARD_PRODUCTS,
});
export const setStopLoading = () => ({
  type: type.STOP_GET_ALL_REWARD_PRODUCTS,
});
export const setRewardProducts = (payload = []) => ({
  type: type.SET_ALL_REWARD_PRODUCTS,
  payload,
});
export const setTotalPage = (payload = 0) => ({
  type: type.SET_TOTAL_PAGES,
  payload,
});
export const removeProducts = () => ({
  type: type.CLEAR_REWARD_PRODUCTS,
});
