import * as type from "./type";

export const initState = {
    isLoading: false,
    memberCardInfo: {},
}

export const Reducer = (state = initState,action) => {
    switch (action.type) {
        case type.START_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case type.STOP_LOADING:
            return {
                ...state,
                isLoading: false,
            }
        case type.GET_MEMBER_CARD:
            return {
                ...state,
                memberCardInfo: action.payload,
            }
        default:
            return state;
    }
}

export default Reducer;

export const set_start_loading = () => ({
    type: type.START_LOADING,
})

export const set_stop_loading = () => ({
    type: type.STOP_LOADING,
})

export const set_member_card_info = (payload) => ({
    type: type.GET_MEMBER_CARD,
    payload,
})