import { combineReducers } from "redux";

import auth from "./reducer.auth";
import home from "./reducer.home";
import survey from "./reducer.survey";
import myAccount from "./reducer.myaccount";
import productDetails from "./reducer.productdetails";
import productDetailsReward from "./reducer.productdetails-reward";
import shoppingCart from "./reducer.shoppingcart";
import shoppingCartReward from "./reducer.shoppingcart-reward";
import productSearch from "./reducer.productsearch";
import orderHistory from "./reducer.orderhistory";
import orderDetail from "./reducer.orderdetail";
import notifications from "./reducer.notifications";
import wishList from "./reducer.wishlist";
import byBrand from "./reducer.bybrand";
import deliveryAddressChange from "./reducer.deliveryAddressChange";
import recommendProducts from "./reducer.recommend";
import rewardProducts from "./reducer.reward";
import variationBox from "./reducer.variation-box";
import productCard from "./reducer.product-card";
import pointDetail from "./reducer.point-detail";
import viewAllMemberLevels from "./reducer.view-all-member-levels";
import history from "./reducer.history";
import payBackProducts from "./reducer.pay-back-point.js";
import redeemWithCode from "./reducer.redeem-with-code";
import shopByBrands from "./reducer.shopbybrands";
import memberCard from "./reducer.membercard";
import deleteAccount from "./reducer.deleteaccount";
import orderDetailForApproval from "./reducer.orderdetail-for-approval";
import shoppingCartCount from "./reducer.shoppingcart-icon";
import searchByBrands from "./reducer.searchbybrands";
import bySubCategory from "./reducer.bysubcategory";

export default combineReducers({
  home: home,
  auth: auth,
  survey: survey,
  myAccount: myAccount,
  productDetails: productDetails,
  productDetailsReward: productDetailsReward,
  shoppingCart: shoppingCart,
  shoppingCartReward: shoppingCartReward,
  productSearch: productSearch,
  orderHistory: orderHistory,
  orderDetail: orderDetail,
  notifications: notifications,
  wishList: wishList,
  byBrand: byBrand,
  deliveryAddressChange: deliveryAddressChange,
  recommend: recommendProducts,
  reward: rewardProducts,
  variationBox: variationBox,
  productCard: productCard,
  pointDetail: pointDetail,
  viewAllMemberLevels: viewAllMemberLevels,
  history: history,
  payBackProducts: payBackProducts,
  redeemWithCode: redeemWithCode,
  shopByBrands,
  memberCard,
  deleteAccount,
  orderDetailForApproval,
  shoppingCartCount,
  searchByBrands,
  bySubCategory,
});
