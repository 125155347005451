import * as type from "./type";

const initState = {
  isLoading: false,
  isSecLoading: false,
  history: [],
  totalPages: 0,
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case type.START_HISTORY_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case type.START_HISTORY_SECONDARY_LOADING:
      return {
        ...state,
        isSecLoading: true,
      };
    case type.STOP_HISTORY_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case type.STOP_HISTORY_SECONDARY_LOADING:
      return {
        ...state,
        isSecLoading: false,
      };
    case type.HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case type.CLEAN_HISTORY:
      return {
        ...state,
        history: [],
      };
    case type.SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;

export const set_start_loading = () => ({
  type: type.START_HISTORY_LOADING,
});

export const set_start_sec_loading = () => ({
  type: type.START_HISTORY_SECONDARY_LOADING,
});

export const set_stop_loading = () => ({
  type: type.STOP_HISTORY_LOADING,
});

export const set_stop_sec_loading = () => ({
  type: type.STOP_HISTORY_SECONDARY_LOADING,
});

export const setHistory = (payload) => ({
  type: type.HISTORY,
  payload,
});

export const cleanHistory = () => ({
  type: type.CLEAN_HISTORY,
});

export const setTotalPages = (payload) => ({
  type: type.SET_TOTAL_PAGES,
  payload,
});
