/**
 * Action Types
 */
import * as type from "./type";

const initialState = {
  isHeaderSectionLoading: false,
  isProductSectionLoading: false,
  recommendProductHeader: {},
  relatedProducts: [],
  totalPages: 0,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.START_RECOMMEND_HEADER_LOADING:
      return {
        ...state,
        isHeaderSectionLoading: true,
      };

    case type.STOP_RECOMMEND_HEADER_LOADING:
      return {
        ...state,
        isHeaderSectionLoading: false,
      };

    case type.START_RELATED_RECOMMEND_PRODUCTS:
      return {
        ...state,
        isProductSectionLoading: true,
      };

    case type.STOP_RELATED_RECOMMEND_PRODUCTS:
      return {
        ...state,
        isProductSectionLoading: false,
      };

    case type.GET_RECOMMEND_HEADER:
      return {
        ...state,
        recommendProductHeader: action.payload,
      };

    case type.GET_RELATED_RECOMMEND_PRODUCTS:
      return {
        ...state,
        relatedProducts: action.payload,
      };

    case type.SET_TOTAL_PAGES_RECOMMEND:
      return {
        ...state,
        totalPages: action.payload,
      };

    case type.CLEAR_RELATED_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        relatedProducts: [],
      };

    case type.CLEAR_RECOMMENDED_HEADER:
      return {
        ...state,
        recommendProductHeader: {},
      };

    default:
      return state;
  }
};

export default Reducer;

/**
 * Actions Methods
 */

export const setStartRecommendHeaderLoading = () => ({
  type: type.START_RECOMMEND_HEADER_LOADING,
});
export const setStopRecommendHeaderLoading = () => ({
  type: type.STOP_RECOMMEND_HEADER_LOADING,
});

export const setStartRelatedRecommendProductsLoading = () => ({
  type: type.START_RELATED_RECOMMEND_PRODUCTS,
});
export const setStopRelatedRecommendProductsLoading = () => ({
  type: type.STOP_RELATED_RECOMMEND_PRODUCTS,
});

export const setRecommendHeader = (payload) => ({
  type: type.GET_RECOMMEND_HEADER,
  payload,
});
export const setRelatedRecommendProducts = (payload) => ({
  type: type.GET_RELATED_RECOMMEND_PRODUCTS,
  payload,
});

export const setTotalPage = (payload) => ({
  type: type.SET_TOTAL_PAGES_RECOMMEND,
  payload,
});

export const clearRelatedRecommendProducts = () => ({
  type: type.CLEAR_RELATED_RECOMMENDED_PRODUCTS,
});

export const clearRecommendedHeader = () => ({
  type: type.CLEAR_RECOMMENDED_HEADER,
});
