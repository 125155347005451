import { SHOPPINGCART_COUNT } from "./type";

const initState = {
  shoppingCartCount: 0,
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case SHOPPINGCART_COUNT:
      return {
        ...state,
        shoppingCartCount: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;

export const setShoppingCartCount = (payload) => ({
  type: SHOPPINGCART_COUNT,
  payload,
});
