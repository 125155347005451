import * as type from "./type";

const initState = {
  isSecLoading: false,
  detail: {},
  variants: [],
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case type.GET_PRODUCT_DETAIL_FOR_VARIATION:
      return {
        ...state,
        detail: action.payload,
      };

    case type.POP_UP_VARIANTS:
      return {
        ...state,
        variants: action.payload,
      };
    case type.START_SECONDARY_LOADING:
      return {
        ...state,
        isSecLoading: true,
      }
    case type.STOP_SECONDARY_LOADING:
      return {
        ...state,
        isSecLoading: false,
      }
    default:
      return state;
  }
};

export const setProductDetail = (payload) => ({
  type: type.GET_PRODUCT_DETAIL_FOR_VARIATION,
  payload,
});

export const setVariation = (payload) => ({
  type: type.POP_UP_VARIANTS,
  payload,
});

export const set_start_sec_loading = () => ({
  type: type.START_SECONDARY_LOADING,
})

export const set_stop_sec_loading = () => ({
  type: type.STOP_SECONDARY_LOADING,
})

export default Reducer;
